import React from 'react';
import { ReactComponent as IconGithub } from './assets/icons/github.svg';
import { ReactComponent as IconMangaba } from './assets/icons/mangaba.svg';
import "./App.css";

const App: React.FC = () => {
  return (
    <div className="card">
      <div className="header">
        <div className="logo">
          <a href=".">
            <IconMangaba className="icon mangaba-icon" />
          </a>
        </div>
        <div className="social">
          <a href="https://github.com/mangaba-io" title="GitHub" target="_blank" rel="noopener noreferrer">
            <IconGithub className="icon" />
          </a>
        </div>
      </div>
      <div className="content">
        <div className="title-holder">
          <h1>Get ready for the change.</h1>
          <p>Website coming soon. Please check back to know more. Shoot us an email if you're curious.</p>
        </div>
        <a href="mailto:hello@mangaba.io">
          <div className="cta">Send us an email</div>
        </a>
      </div>
      <div className="footer">
        <span>2024 <a className="underlined" href="https://mangaba.io/" target="_blank" rel="noopener noreferrer"><b>mangaba.io</b></a>. All rights reserved.</span>
      </div>
    </div>
  );
}

export default App;
